import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Typeform, Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 0";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "youre-well-on-your-way-to-build-something-great"
    }}>{`You're well on your way to build something great`}</h1>
    <p>{`Hi, you're the best ❤️`}</p>
    <p>{`Hope you enjoyed your ServerlessReact.Dev journey. I'm super excited to see what you build!`}</p>
    <p>{`We covered a bunch of topics and it's okay, if they're still a little shakey. `}<em parentName="p">{`Now`}</em>{`'s the best time to go use them for a project of your own.`}</p>
    <Vimeo id="407198463" mdxType="Vimeo" />
    <p>{`Before you go, could you answer a couple questions? Only takes 5 minutes`}</p>
    <Typeform url="https://swizecteller.typeform.com/to/SVQpYZ" mdxType="Typeform" />
    <p>{`Cheers,`}<br />{`
~Swizec`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      